/**
 * Adds a script to the document head and executes a callback function when the script is loaded.
 * If the script fails to load, it does not attempt to reload it.
 *
 * @param {string} src - The source URL of the script to be added.
 * @param {Function} onLoad - The callback function to be executed once the script is loaded.
 */
const addScript = (src: string, onLoad: () => void) => {
  const existingScript = document.querySelector(`script[src="${src}"]`);

  if (existingScript) {
    if (existingScript.getAttribute('data-loaded') === 'true') {
      onLoad();
    } else {
      existingScript.addEventListener('load', onLoad);
    }
  } else {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.onload = () => {
      script.setAttribute('data-loaded', 'true');
      onLoad();
    };
    script.onerror = () => {
      console.error(`Error loading script: ${src}`);
    };
    document.head.appendChild(script);
  }
};

export default addScript;
