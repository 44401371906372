import { InterfacesType } from '@jarvis/shell-commons/dist/interface';
import { registerTask } from './backgroundTaskService';
import { bgtInjectFullstorySnippet, bgtInjectHpIdModule } from './tasks';

declare global {
  interface Window {
    Shell: InterfacesType;
  }
}

const registerAllTasks = async () => {
  return Promise.allSettled([
    registerTask('bgtInjectHpIdModule', bgtInjectHpIdModule),
    registerTask('bgtInjectFullstorySnippet', bgtInjectFullstorySnippet)
  ]);
};

export default registerAllTasks;
