import {
  EnumAllowedLogType,
  LogFuncType
} from '@jarvis/shell-commons/dist/interface/v1/logger/types';

declare global {
  interface Window {
    _O: {
      ifOptInGroups: (group: string) => boolean;
    };
    FS?: {
      log?: (message: string) => void;
    };
  }
}

export const getFullstoryFeatureFlagValue = async (
  logger: Record<EnumAllowedLogType, LogFuncType>
): Promise<boolean> => {
  try {
    const client = await window?.Shell?.v1?.featureFlags?.getClient(
      'myaccount'
    );
    const ff = await client?.getFeatureFlag({
      key: 'fullstory',
      defaultValue: false
    });

    return ff as boolean;
  } catch (error) {
    logger.error('error getting feature flag', error);
    return false;
  }
};

export const loadFullStoryScript = async (
  logger: Record<EnumAllowedLogType, LogFuncType>
) => {
  const FULLSTORY_SCRIPT_SRC = 'edge.fullstory.com/s/fs.js';
  const FULLSTORY_HOST = 'fullstory.com';
  const FULLSTORY_ORG = 'o-21C08X-na1';
  const FULLSTORY_NAMESPACE = 'FS';

  const ff = await getFullstoryFeatureFlagValue(logger);

  if (!ff) {
    logger.log('feature flag is false');
    return;
  }

  const existingScript = document.querySelector(
    `script[src*="${FULLSTORY_SCRIPT_SRC}"]`
  );

  if (existingScript) {
    logger.log('script already loaded');
    return;
  }

  const scriptTag = document.createElement('script');
  scriptTag.type = 'text/javascript';
  scriptTag.async = true;
  scriptTag.dataset.testid = 'fullstory-script';
  window['_fs_host'] = FULLSTORY_HOST;
  window['_fs_script'] = FULLSTORY_SCRIPT_SRC;
  window['_fs_org'] = FULLSTORY_ORG;
  window['_fs_namespace'] = FULLSTORY_NAMESPACE;
  scriptTag.src = `https://${FULLSTORY_SCRIPT_SRC}`;
  scriptTag.onload = () => {
    logger.log('script loaded successfully');
    // Verify if FullStory is tracking user interactions
    if (window.FS && typeof window.FS.log === 'function') {
      logger.log('is tracking user interactions');
    } else {
      logger.log('is not tracking user interactions');
    }
  };
  scriptTag.onerror = (error) => {
    logger.error('error loading script', error);
  };

  document.head.appendChild(scriptTag);
};

/**
 * Waits for the window._O object to be available and then executes the callback function.
 * Logs an error if the object is not available within the specified timeout.
 *
 * @param {Record<EnumAllowedLogType, LogFuncType>} logger - The logger instance to log messages.
 * @param {() => void} callback - The callback function to execute once the window._O object is available.
 * @param {number} [interval=100] - The interval in milliseconds to check for the window._O object.
 * @param {number} [timeout=5000] - The timeout in milliseconds to wait for the window._O object.
 */
const waitForOObject = (
  logger: Record<EnumAllowedLogType, LogFuncType>,
  callback: () => void,
  interval = 100,
  timeout = 5000
) => {
  const startTime = Date.now();

  const checkAvailability = () => {
    if (window._O) {
      callback();
    } else if (Date.now() - startTime < timeout) {
      setTimeout(checkAvailability, interval);
    } else {
      logger.error(`window._O object not found after waiting for ${timeout}ms`);
    }
  };

  checkAvailability();
};

/**
 * Background Task: bgtInjectFullstorySnippet
 *
 * This function asynchronously injects the FullStory snippet into the page. It waits for the OObject to be ready and checks if the user
 * is in the specified opt-in group ('2:1'). If the user is in the group, and the Fullstory feature flag is true
 * it loads the FullStory script.
 *
 *  * Process Details:
 * 1. Wait for the OObject to be ready. If the OObject is not available after 5 seconds, log an error.
 * 2. Check if the user has accepted the analytics agreement. Opt-in group ('2:1')
 * 3. Check the FullStory feature flag value.
 * 4. Load the FullStory script if the user is in the specified opt-in group and the feature flag is true.
 * 5. Log errors and throw exceptions on failure.
 *
 * @returns {Promise<void>} A promise that resolves when the operation is complete.
 */
export const bgtInjectFullstorySnippet = async (): Promise<void> => {
  const logger = window.Shell.v1.logger.createLoggerInstance({
    preffixLog: 'Fullstory:'
  });

  waitForOObject(logger, async () => {
    if (window._O.ifOptInGroups('2:1')) {
      logger.log('user is in opt-in group');
      await loadFullStoryScript(logger);
    } else {
      logger.log('user is not in opt-in group');
    }
  });
};
